import React from 'react';
import { AppBar, Toolbar, Typography, Container } from '@mui/material';
import Logo from '../assets/draiver.png'; // Ersetze dies mit dem Pfad zu deinem Logo

const Header = () => {
  return (
    <AppBar position="sticky" sx={{ backgroundColor: 'primary.main' }}>
      <Container>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <img src={Logo} alt="Logo" style={{ height: '110px' }} />
            <Typography variant="h6" sx={{ display: { xs: 'none', sm: 'block' } }}>
              ...hier Lernst du Theorie intelligent
            </Typography>
          </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
