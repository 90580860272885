import React, { useState } from 'react';
import Header from './components/Header';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import HomePage from './components/HomePage';
import QuizStep from './components/QuizStep';
import ResultPage from './components/ResultPage';
import theme from './theme';

function App() {
  const [results, setResults] = useState([]);

  const handleAnswer = (step, answer) => {
    const updatedResults = [...results];
    updatedResults[step] = answer;
    setResults(updatedResults);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/quiz/:step"
            element={<QuizStep onAnswer={handleAnswer} />}
          />
          <Route path="/result" element={<ResultPage results={results} />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;

