import { createTheme } from '@mui/material/styles';

const neonDarkTheme = createTheme({
  palette: {
    mode: 'dark', // Aktiviert den Dark Mode
    primary: {
      main: '#00e676', // Neon-Grün
    },
    secondary: {
      main: '#ff4081', // Neon-Pink
    },
    background: {
      default: '#121212', // Dunkler Hintergrund
      paper: '#1e1e1e', // Dunklere Kartenfarbe
    },
    text: {
      primary: '#e0e0e0', // Helle Schrift
      secondary: '#bdbdbd', // Weniger dominante Schrift
    },
  },
  typography: {
    fontFamily: '"Roboto", "Arial", sans-serif',
    h3: {
      fontWeight: 700,
      color: '#00e676', // Neon-Grün für Überschriften
      textShadow: '0px 0px 8px #00e676', // Leichte Leucht-Effekte
    },
    h6: {
      color: '#333',
      fontWeight: 800
    },
    body1: {
      fontSize: '1rem',
      color: '#e0e0e0', // Helle Schrift für normalen Text
    },
    button: {
      textTransform: 'none', // Entfernt Großbuchstaben
      fontWeight: 'bold',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px', // Abgerundete Buttons
          boxShadow: '0px 0px 8px #00e676', // Neon-Leuchteffekt
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#1e1e1e', // Dunkle Kartenfarbe
          borderRadius: '12px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.7)', // Sanfte Schatten
        },
      },
    },
  },
});

export default neonDarkTheme;
