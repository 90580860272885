import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Button } from '@mui/material';

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '70vh',
        backgroundColor: 'background.default', // Verwendet zentrale Hintergrundfarbe
      }}
    >
      <Card sx={{ maxWidth: 600, width: '100%', textAlign: 'center' }}>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            Starte mit deiner Übung
          </Typography>
          <Typography variant="body1" gutterBottom>
            mache weiter wo du aufgehört hast
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate('/quiz/1')}
          >
            Jetzt lernen
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default HomePage;
