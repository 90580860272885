import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Box,
  Divider,
} from '@mui/material';

export const questions = [
  { id: 1, question: 'Wer hat Vorfahrt?', options: ['Das grüne Auto', 'Der Fußgänger', 'Das Fahrrad'], correct: 1 },
  { id: 2, question: 'Du hast einen Fußgänger angefahren, was machst du?', options: ['schnell weg', 'nochmal drüber', 'nichts'], correct: 0 },
  { id: 3, question: 'Wie viele Planeten hat unser Sonnensystem?', options: ['7', '8', '9'], correct: 1 },
];

const QuizStep = ({ onAnswer }) => {
  const { step } = useParams();
  const navigate = useNavigate();
  const currentStep = parseInt(step) - 1;
  const question = questions[currentStep];
  const [selectedOption, setSelectedOption] = useState(null);

  const handleNext = () => {
    if (selectedOption === null) return;
    onAnswer(currentStep, selectedOption);
    if (currentStep + 1 < questions.length) {
      navigate(`/quiz/${currentStep + 2}`);
    } else {
      navigate('/result');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 3,
        backgroundColor: 'background.default',
        minHeight: '100vh',
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: 3 }}>
        Frage {currentStep + 1} von {questions.length}
      </Typography>
      <Card sx={{ width: '100%', maxWidth: 800, marginBottom: 2 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {question.question}
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />
          <RadioGroup
            value={selectedOption}
            onChange={(e) => setSelectedOption(parseInt(e.target.value))}
          >
            {question.options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={index}
                control={<Radio />}
                label={option}
                sx={{
                  '& .MuiTypography-root': { fontSize: '1rem' },
                }}
              />
            ))}
          </RadioGroup>
        </CardContent>
      </Card>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={handleNext}
        sx={{ width: '100%', maxWidth: 800, marginTop: 2 }}
      >
        {currentStep + 1 < questions.length ? 'Weiter' : 'Ergebnis anzeigen'}
      </Button>
    </Box>
  );
};

export default QuizStep;
