import React from 'react';
import { Box, Card, CardContent, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { questions } from './QuizStep';

const ResultPage = ({ results }) => {
  const navigate = useNavigate();
  const correctAnswers = results.filter(
    (answer, index) => answer === questions[index].correct
  ).length;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: 'background.default', // Verwendet zentrale Hintergrundfarbe
      }}
    >
      <Card sx={{ maxWidth: 600, width: '100%' }}>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Ergebnis
          </Typography>
          <Typography variant="h5" gutterBottom>
            Du hast {correctAnswers} von {questions.length} richtig beantwortet!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => navigate('/')}
            sx={{ marginTop: 2 }}
          >
            Zurück zur Startseite
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ResultPage;
